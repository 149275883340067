.gradient-custom {
    background: #6a11cb;
    background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card {
    border-radius: 1rem;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5);
}

/* Add more custom styles as needed */
