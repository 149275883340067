/* ProductList.module.css */

.list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
    padding: 0 20px;
}

.item {
    text-align: center;
    background-color: #DCE0E1;
    padding: 10px;
    box-shadow: 0 10px 10px #757676;
}

.item img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}

.item .title {
    font-weight: 600;
    margin-top: 10px;
}

.item .price {
    margin: 10px 0;
}

.item button {
    background-color: #1C1F25;
    color: #fff;
    width: 100%;
    padding: 10px;
    border: none;
    cursor: pointer;
}
